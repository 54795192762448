import React from "react";
import { InputGroup, Input, InputRightElement } from "@chakra-ui/react";
import { TimeIcon } from "@chakra-ui/icons";

const CustomTimeInput = ({ value, onClick }) => (
    <InputGroup>
      <Input
        borderRadius={2}
        value={value}
        onClick={onClick}
      />
      <InputRightElement>
        <TimeIcon color="gray.500" mt={0} mr={2} />
      </InputRightElement>
    </InputGroup>
  );

  export default CustomTimeInput;