import React from "react";
import {
  Box,
  Flex,
  Button,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

import shumenkovski_black from "../images/shumenkovski_black.png";
import shumenkovska_black from "../images/shumenkovska_black.png";
import logo from "../images/logo.png";

function HeaderMenuBigScreen() {

  return (
    <Box
      bg="#C5E6EB"
      width="100%"
      display="grid" 
      boxShadow='dark-lg'
      gridTemplateColumns="auto 1fr"
    >
      {/* <Flex
        px={60}
        position="absolute"
        display={{ base: "none", md: "flex" }}
      >
        <Image h="6rem" src={logo} />
      </Flex> */}

      <Flex
        pt={2}
        justifyContent="center"
        alignItems="center"
        gridColumn="span 2"
      >
        <Image height="5rem" src={logo}/>
        <Image height="50px" width="270px" src={shumenkovski_black} />
      </Flex>

      <Box
        my={2}
        gridColumn="span 2"
        justifyContent="center"
        display={{ base: "none", md: "flex" }}
      >
        <HStack spacing={4}>
          <Button
            bg="none"
            borderRadius={0}
            _hover={{ bg: "#e5efef", textColor: "#21b3c6" }}
            textColor="#0d0d5d"
            fontSize={22}
            fontWeight="bold"
          >
            <a href="/">Почетна</a>
          </Button>

          <Button
            bg="none"
            borderRadius={0}
            _hover={{ bg: "#e5efef", textColor: "#21b3c6" }}
            textColor="#0d0d5d"
            fontSize={22}
            fontWeight="bold"
          >
            <a href="/about">За нас</a>
          </Button>

          <Menu>
            <MenuButton
              as={Button}
              bg="none"
              borderRadius={0}
              _hover={{ bg: "#e5efef", textColor: "#21b3c6" }}
              _active={{ bg: "#e5efef", textColor: "#21b3c6" }}
              textColor="#0d0d5d"
              fontWeight="bold"
              fontSize={22}
              rightIcon={<ChevronDownIcon />}
            >
              Услуги
            </MenuButton>
            <MenuList>
              <MenuItem>
                <Link to="/services/1">Превентивна стоматологија</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/services/2">Конзервативна стоматологија</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/services/3">Ендодонција</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/services/4">Детска стоматологија</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/services/5">Протетика</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/services/6">Ортодонција</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/services/7">Рендген дијагностика</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/services/8">Пародонтолошки третмани</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/services/9">Белење заби</Link>
              </MenuItem>
            </MenuList>
          </Menu>

          <Button
            bg="none"
            borderRadius={0}
            _hover={{ bg: "#e5efef", textColor: "#21b3c6" }}
            textColor="#0d0d5d"
            fontSize={22}
            fontWeight="bold"
          >
            <a href="/beforeAfter">Пред и Потоа</a>
          </Button>

          <Button
            bg="none"
            borderRadius={0}
            _hover={{ bg: "#e5efef", textColor: "#21b3c6" }}
            textColor="#0d0d5d"
            fontSize={22}
            fontWeight="bold"
          >
            <a href="/contact">Контакт</a>
          </Button>
        </HStack>
      </Box>

      
    </Box>
  );
}

export default HeaderMenuBigScreen;
