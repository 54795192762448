import React from "react";
import { BrowserRouter, } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import AppContent from "./components/AppContent";

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
