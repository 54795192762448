import React from "react";

import { useIsMobile } from "../default/ResponsiveUtils";
import HeaderMenuBigScreen from "./HeaderMenuBigScreen";
import HamburgerMenu from "./HamburgerMenu";

const Header = () => {
     const isMobile = useIsMobile();

     return (
          <>
               {isMobile  ? (<HamburgerMenu />) : (<HeaderMenuBigScreen/>)}
          </>
     );
}

export default Header;