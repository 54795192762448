import React,{ useState, useEffect } from "react";
import { Heading, VStack, Box, Text } from "@chakra-ui/react";
import { useIsMobile } from "../default/ResponsiveUtils";
import EmployeeCard from "./EmployeeCard";
import EmployeesList from "../default/EmployeeList";
import { useLocation } from "react-router-dom";

function About() {

  const dentalDescription = `Стоматолошка ординација “ШУМЕНКОВСКИ” е основана 1998 година и е една од првите приватни
   ординации во Струга. Лоцирана е во срцето на градот и цели 25 години се трудиме секој 
   пациент да добие најквалитетна стоматолошка услуга. Најпрво започнува со работа како 
   ординација по општа стоматологија, од 2000 година со сопствен ренген кабинет кој 
   овозможува изработка на ортопантомографски и интраалвеоларни снимања, а подоцна се 
   проширува и со специјалистичка ординација од областа на ортодонција. Ординацијата има 
   Договор со Фондот за здравствено осигурување.\nПрепознатливи сме по пријателски однос, сеопфатна грижа за нашите пациенти,
   професионалност и љубезност.\nСтоматолог не се избира преку ноќ.\nПрепуштете на нас и сонот за убава и здрава насмевка нека стане реалност.\nУверете се и Вие во нашиот долгогодишен квалитет! Овозможете си насмевка од соништата!`

   const isMobile = useIsMobile();
   const location = useLocation();
   const [currentPath, setCurrentPath] = useState(location.pathname);
   useEffect(() => {
     setCurrentPath(location.pathname);
   }, [currentPath]);

  return (
    <VStack minHeight="50vh" width={isMobile ? "20rem" : "56rem"} mb={5}>
      <Box pb={10} pt={5}>
        <Box  pb={5} alignContent="center">
          <Heading 
            pb={3} 
            width={isMobile ? "20rem" : "full"}
            bg="#50bbcc" textAlign="center" alignContent="center"
            textColor="whitesmoke"
            border="2px solid #0792a8"
          >
            За нас
          </Heading>
        </Box>
        <Box
          p={3}
          bg="#c2eaf0"
          display="flex"
          flexDirection="column"
          alignItems="center"
          border="2px solid #44838d"
        >
          <Box textAlign="center" fontStyle="italic">
            <Text
              fontSize={18}
              textColor="#104d57"
              overflowWrap="break-word"
              whiteSpace="pre-line"
              dangerouslySetInnerHTML={{ __html: dentalDescription }}
            />
          </Box>
        </Box>
      </Box>

      <Box>
        <Box textAlign="center" p={2}>
          <Heading
            textColor="whitesmoke"
            bg="#50bbcc"
            border="2px solid #0792a8"
            p={2}
          >
            Нашиот тим
          </Heading>
        </Box>

        {EmployeesList.map((employee) => (
          <EmployeeCard key={employee.id} employee={employee} />
        ))}
      </Box>
    </VStack>
  );
}

export default About;
