import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Stack,
  Text,
  Box,
  Button,
} from "@chakra-ui/react";

const EmployeeCard = ({ employee }) => {
  const [showFullText, setShowFullText] = useState(false);
  const textRef = useRef(null);

  const [truncatedText, setTruncatedText] = useState(
    employee.fullText.substring(0, 300) + "..."
  );

  const toggleFullText = () => {
    setShowFullText(!showFullText);

    if (!showFullText) {
      const lineHeight = parseFloat(
        window.getComputedStyle(textRef.current).lineHeight
      );
      const maxHeight = lineHeight * 3;
      const isTruncated = textRef.current.scrollHeight > maxHeight;

      if (isTruncated) {
        setTruncatedText(textRef.current.innerText);
      }
    } else {
      setTruncatedText(employee.fullText.substring(0, 300) + "...");
    }
  };

  useEffect(() => {
    if (textRef.current) {
      const lineHeight = parseFloat(
        window.getComputedStyle(textRef.current).lineHeight
      );
      const maxHeight = lineHeight * 3;
      const isTruncated = textRef.current.scrollHeight > maxHeight;
      if (isTruncated) {
        setTruncatedText(textRef.current.innerText);
      }
    }
  }, [showFullText]);

  return (
    <Box p={2}>
      <Card
        border="2px solid #44838d"
        bg="#c2eaf0"
        pb={2}
        pl={3}
        borderRadius={2}
        direction={{ base: "column", sm: "row" }}
        overflow="hidden"
        variant="none"
      >
        <Image mt={5}
          alignSelf="center"
          objectFit="cover"
          width="155px"
          height="155px"
          src={employee.imagePath}
          borderRadius="50%"
        />
        <Stack>
          <CardBody pb={1}>
            <Heading size="md" pb={2} mt={-2} color="#104d57">
              {employee.heading}
            </Heading>
            <Text
              color="#104d57"
              fontSize={18}
              overflowWrap="break-word"
              whiteSpace="pre-line"
              ref={textRef}
              dangerouslySetInnerHTML={{
                __html: showFullText ? employee.fullText : truncatedText,
              }}
            />
          </CardBody>

          <CardFooter
            pt={0}
            pb={2}
            justifyContent="space-between"
            alignSelf="flex-end"
          >
            <Button
              variant="solid"
              onClick={toggleFullText}
              width="6rem"
              borderRadius={2}
              bg="#21b3c6"
              textColor="white"
              _hover={{ bg: "#e5efef", textColor: "#21b3c6" }}
              fontWeight="bold"
            >
              {showFullText ? "Помалку" : "Повеќе"}
            </Button>
          </CardFooter>
        </Stack>
      </Card>
    </Box>
  );
};

export default EmployeeCard;
