const ServiceListEnum = {
     PreventivnaStomatologija: 1,
     KonzervativnaStomatologija: 2,
     Endodencija: 3,
     DetskaStomatologija: 4,
     Protetika: 5,
     Ortodoncija: 6,
     RendgenDijagnostika: 7,
     ParadontoloskiTretmani: 8,
     BelenjeNaZabi: 9,
   };
   
export default ServiceListEnum;