import React from "react";
import { Image, Box, Text } from "@chakra-ui/react";

import { useIsMobile } from "../default/ResponsiveUtils";

const ImageSlide = ({ image, description }) => {
  const isMobile = useIsMobile();

  return (
    <Box position="relative">
      <Image src={image} width={isMobile ? "26rem" : "35rem"} height={isMobile ? "20rem" : "35rem"} justifySelf="center" />
      {description && (
        <Box
          position="absolute"
          top="55%"
          width="50%"
          pl="3%"
          pr="1%"
          bg="#44838d"
          border="4px #2e6d75"
        >
          <Text
            fontSize={isMobile ? 20 : 36}
            fontFamily="serif"
            fontWeight="bold"
            overflowWrap="break-word"
            textColor="#edf4f5"
          >
            {description}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default ImageSlide;
