import React from "react";

import homeBack1 from "../images/dentOffice.jpg";
import rendgen from "../images/rendgen.jpg";
import homebackground from "../images/homebackground1.png";
import together from "../images/together.jpg";
import CarouselComponent from "./CarouselComponent";

function Home() {
  const slides = [
    {
      image: homeBack1,
      description: "Стоматолошка ординација со повеќе од 25 години искуство!",
    },
    {
      image: together,
      description: "Together we are strong!",
    },
    {
      image: rendgen,
    },
    {
      image: homebackground,
      description: "Нудиме најквалитетни стоматолошки услуги!",
    },
  ];

  return (
      <CarouselComponent slides={slides} />
  );
}

export default Home;
