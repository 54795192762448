import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Box } from "@chakra-ui/react";

import About from "./About";
import Home from "./Home";
import Header from "./Header";
import Contact from "./Contact";
import Footer from "./Footer";
import Services from "./Services";
import BeforeAndAfter from "./BeforeAndAfter";
import { useIsMobile } from "../default/ResponsiveUtils";

function AppContent() {
  const location = useLocation();
  const [currentPathId, setCurrentPathId] = useState("");
  const isMobile = useIsMobile();

  useEffect(() => {
    setCurrentPathId(location.pathname);
  }, [[location.pathname]]);

  const isLastCharacterNumber = !isNaN(parseInt(currentPathId.slice(-1), 10));

  return (
    <Box 
    style={{
          background:
            "linear-gradient(45deg, #e5efef 25%, #ffffff 25%, #ffffff 50%, #e5efef 50%, #e5efef 75%, #ffffff 75%, #ffffff 100%)",
        }}
    >
      <Header />

      <Box
        flexDirection="column"
        display="flex"
        position="relative"
        justifyContent="center"
        alignItems="center"
        p={(isLastCharacterNumber || isMobile) ? 0 : 10}
        minH="50vH" 
        // style={{
        //   background:
        //     "linear-gradient(45deg, #e5efef 25%, #ffffff 25%, #ffffff 50%, #e5efef 50%, #e5efef 75%, #ffffff 75%, #ffffff 100%)",
        // }}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services/:id" element={<Services />} />
          <Route path="/beforeAfter" element={<BeforeAndAfter />} />
        </Routes>
      </Box>

      <Box as={Footer}>
        <Footer />
      </Box>
    </Box>
  );
}

export default AppContent;
