import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  SimpleGrid,
  GridItem,
  useToast,
  Box,
  FormErrorMessage,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";
import { setHours, setMinutes } from "date-fns";
import CustomDateInput from "./CustomDateInput";
import CustomTimeInput from "./CustomTimeInput";
import { useIsMobile } from "../default/ResponsiveUtils";

const AppointmentModal = ({ isOpen, onClose }) => {
  const toast = useToast();
  const services = [
    "Превентивна стоматологија",
    "Конзервативна стоматологија",
    "Ендодонција",
    "Детска стоматологија",
    "Протетика",
    "Ортодонција",
    "Рендген дијагностика",
    "Пародонтолошки третмани",
    "Белење заби",
  ];

  const lettersOnlyMessage = "Дозволени се само букви за ова поле!";
  const numOnlyMessage = "Дозволени се само нумерички вредности за ова поле!";
  const emailRegexMessage = "Невалиден формат!";
  const isMobile = useIsMobile();
  const letterOnlyRegex = /^[aA-zZаА-шШѓЃѕЅјЈљЉњЊќЌџЏ\s]+$/;
  const numRegex = /^[0-9 ]*$/;
  const emailRegex = /^[a-zA-Z0-9_.-]+@[a-zA-Z]+\.[a-zA-Z]+$/;
  let mobileNum = isMobile ? "Тел. број" : "Телефонски број";

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    service: "",
    selectedDate: "",
    selectedTime: "",
  };

  const requiredMessage = (fieldName) => {
    return `${fieldName.charAt(0).toUpperCase()}${fieldName.slice(
      1
    )} е задолжително!`;
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(letterOnlyRegex, {
        message: lettersOnlyMessage,
        excludeEmptyString: true,
      })
      .required(requiredMessage("Име")),

    lastName: Yup.string()
      .matches(letterOnlyRegex, {
        message: lettersOnlyMessage,
        excludeEmptyString: true,
      })
      .required(requiredMessage("Презиме")),

    email: Yup.string()
      .matches(emailRegex, {
        message: emailRegexMessage,
        excludeEmptyString: true,
      })
      .required(requiredMessage("Емаил")),

    phone: Yup.string()
      .required(requiredMessage("Телефонски број"))
      .matches(numRegex, numOnlyMessage),

    service: Yup.string().required(requiredMessage("Услуга")),

    selectedDate: Yup.string().required(requiredMessage("Датум")),

    selectedTime: Yup.string().required(requiredMessage("Време")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const displayToast = () => {
    toast({
      title: "Закажан термин",
      description:
        "Почитуван/а, вашето барање е испратено. Ќе добиете потврда на вашата маил адреса. За дополнителни информации можете да се јавите во нашата ординација.",
      status: "success",
      duration: 9000,
      isClosable: true,
      position: "top",
    });
  };

  const handleSelect = (service) => {
    formik.setFieldValue("service", service);
  };

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formik.values, { abortEarly: false });
      const formattedDate =
        formik.values.selectedDate.toLocaleDateString("en-US");
      const formattedTime = formik.values.selectedTime.toLocaleTimeString();

      const formData = {
        ...formik.values,
        selectedDate: formattedDate,
        selectedTime: formattedTime,
      };
      //FormData = formData;
      console.log("Form Data: ", formData);
      sendEmailTo(formData);
      onClose();
      displayToast();
      
    } 
    catch (errors) 
    {
      Object.keys(formik.values).forEach((field) => {
        formik.setFieldError(field, "");
      });

      errors.inner.forEach((error) => {
        formik.setFieldError(error.path, error.message);
      });
    }
  };

  function sendEmailTo(FormData){
    var formMessage = `Име: ${FormData.firstName} \n Презиме: ${FormData.lastName} \n Телефонски Број: ${FormData.phone} \n E-mail: ${FormData.email} \n Услуга: ${FormData.service} \n Датум: ${FormData.selectedDate} \n Посакувано време: ${FormData.selectedTime}`;
    var templateParams = {
      from_name: FormData.firstName,
      from_email: FormData.email,
      to_name: "Ordinacija Shumenkovski",
      message: formMessage
    };
    
    window.emailjs.send('service_0s1xya7', 'template_m9xoo0s', templateParams).then(
      (response) => {
        console.log('SUCCESS!', response.status, response.text);
        debugger
      },
      (error) => {
        console.log('FAILED...', error);
        debugger
      },
    );
    
  }
  

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent borderRadius={2} maxW="30rem">
        <ModalHeader>Закажете термин</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <FormControl isInvalid={!!formik.errors.firstName}>
                <FormLabel
                  textStyle="sb=13"
                  position="absolute"
                  top="-3"
                  left="2"
                  zIndex="5"
                  bgColor="white"
                  px="2"
                >
                  Име
                </FormLabel>
                <Input
                  borderRadius={2}
                  name="firstName"
                  value={formik.values.firstname}
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      firstname: e.target.value,
                    });
                  }}
                  {...formik.getFieldProps("firstName")}
                />
                {formik.errors.firstName && (
                  <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl mt={5} isInvalid={!!formik.errors.lastName}>
                <FormLabel
                  textStyle="sb=13"
                  position="absolute"
                  top="-3"
                  left="2"
                  zIndex="5"
                  bgColor="white"
                  px="2"
                >
                  Презиме
                </FormLabel>
                <Input
                  borderRadius={2}
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      lastName: e.target.value,
                    });
                  }}
                  {...formik.getFieldProps("lastName")}
                />
                {formik.errors.lastName && (
                  <FormErrorMessage alignSelf="flex-start">
                    {formik.errors.lastName}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl mt={5} isInvalid={!!formik.errors.email}>
                <FormLabel
                  textStyle="sb=13"
                  position="absolute"
                  top="-3"
                  left="2"
                  zIndex="5"
                  bgColor="white"
                  px="2"
                >
                  Е-Маил
                </FormLabel>
                <Input
                  borderRadius={2}
                  name="email"
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      email: e.target.value,
                    });
                  }}
                  {...formik.getFieldProps("email")}
                />
                {formik.errors.email && (
                  <FormErrorMessage alignSelf="flex-start">
                    {formik.errors.email}
                  </FormErrorMessage>
                )}
              </FormControl>

              <SimpleGrid columns={2} spacing={4}>
                <GridItem>
                  <FormControl mt={5} isInvalid={!!formik.errors.phone}>
                    <FormLabel
                      textStyle="sb=13"
                      position="absolute"
                      top="-3"
                      left="2"
                      zIndex="5"
                      bgColor="white"
                      px="2" 
                    >
                      {mobileNum}
                    </FormLabel>
                    <Input
                      borderRadius={2}
                      name="phone"
                      value={formik.values.phone}
                      onChange={(e) => {
                        formik.setValues({
                          ...formik.values,
                          phone: e.target.value,
                        });
                      }}
                      {...formik.getFieldProps("phone")}
                    />
                    {formik.errors.phone && (
                      <FormErrorMessage alignSelf="flex-start">
                        {formik.errors.phone}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mt={5} isInvalid={!!formik.errors.phone}>
                    <Menu>
                      <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                        borderRadius={2}
                        textAlign="left"
                        fontWeight="normal"
                        fontSize="14px"
                        overflowWrap="break-word"
                        whiteSpace="normal"
                        background="white"
                        
                        borderColor={
                          !!formik.errors.service ? "red" : "lightgray"
                        }
                        variant="outline"
                        width="full"
                      >
                        {formik.values.service || "Услуга"}
                      </MenuButton>
                      <MenuList
                        placement="right"
                        borderWidth={1}
                        borderRadius={2}
                        borderColor="gray.200"
                        boxShadow="sm"
                        zIndex={10}
                        position="absolute"
                        left={0}
                        maxHeight="30vh" 
                        overflow="auto"
                        overflowY="auto" 
                      >
                        {services.map((s) => (
                          <MenuItem
                            key={s}
                            onClick={() => handleSelect(s)}
                            pb={1}
                            pt={0.5}
                          >
                            {s}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                    <Input
                      type="hidden"
                      name="service"
                      value={formik.values.service}
                      onChange={(e) => {
                        formik.setValues({
                          ...formik.values,
                          service: e.target.value,
                        });
                      }}
                      {...formik.getFieldProps("service")}
                    />
                    {formik.errors.service && (
                      <FormErrorMessage alignSelf="flex-start">
                        {formik.errors.service}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
              </SimpleGrid>

              <SimpleGrid columns={2} spacing={4}>
                <GridItem>
                  <FormControl
                    mt={5}
                    zIndex={10}
                    isInvalid={!!formik.errors.selectedDate}
                  >
                    <FormLabel
                      textStyle="sb=13"
                      position="absolute"
                      top="-3"
                      left="2"
                      zIndex="5"
                      bgColor="white"
                      px="2"
                    >
                      Датум
                    </FormLabel>
                    <Box
                      sx={{
                        ".react-datepicker-popper": {
                          zIndex: 10,
                        },
                        ".react-datepicker__input-container input": {
                          border: "#cccfd0 1px solid",
                        },
                        ".react-datepicker__input-container:hover input": {
                          border: "#abafb1 1px solid",
                        },
                      }}
                    >
                      <DatePicker
                        selected={formik.values.selectedDate}
                        onChange={(e) => {
                          formik.setValues({
                            ...formik.values,
                            selectedDate: e,
                          });
                        }}
                        dateFormat="yyyy-MM-dd"
                        timeZone="UTC"
                        name="selectedDate"
                        minDate={new Date()}
                        customInput={
                          <CustomDateInput
                            {...formik.getFieldProps("selectedDate")}
                          />
                        }
                      />
                    </Box>
                    {formik.errors.selectedDate && (
                      <FormErrorMessage alignSelf="flex-start">
                        {formik.errors.selectedDate}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl mt={5} isInvalid={!!formik.errors.selectedTime}>
                    <FormLabel
                      textStyle="sb=13"
                      position="absolute"
                      top="-3"
                      left="2"
                      zIndex="5"
                      bgColor="white"
                      px="2"
                    >
                      Време
                    </FormLabel>
                    <Box
                      sx={{
                        ".react-datepicker__input-container input": {
                          border: "#cccfd0 1px solid",
                        },
                        ".react-datepicker__input-container:hover input": {
                          border: "#abafb1 1px solid",
                        },
                      }}
                    >
                      <DatePicker
                        selected={setHours(setMinutes(new Date(), 0), 9)}
                        onChange={(e) => {
                          formik.setValues({
                            ...formik.values,
                            selectedTime: e,
                          });
                        }}
                        showTimeSelect 
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        name="selectedTime"
                        minTime={setHours(setMinutes(new Date(), 0), 9)}
                        maxTime={setHours(setMinutes(new Date(), 30), 16)}
                        customInput={
                          <CustomTimeInput
                            {...formik.getFieldProps("selectedTime")}
                          />
                        }
                      />
                    </Box>
                    {formik.errors.selectedTime && (
                      <FormErrorMessage alignSelf="flex-start">
                        {formik.errors.selectedTime}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
              </SimpleGrid>
            </Form>
          </FormikProvider>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            borderRadius={2}
            onClick={handleSubmit}
          >
            Испрати
          </Button>
          <Button onClick={onClose} borderRadius={2}>
            Откажи
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AppointmentModal;
