import React from "react";
import {
  Box,
  Flex,
  Text,
  Link,
  Icon,
  AspectRatio,
  Image,
} from "@chakra-ui/react";
import { EmailIcon, PhoneIcon, TimeIcon } from "@chakra-ui/icons";

import { useIsMobile } from "../default/ResponsiveUtils";
import shumenkovska_black from "../images/shumenkovska_black.png";
import { FacebookIcon, InstagramIcon, LocationIcon } from "../icons/icons";

const Footer = () => {
  const isMobile = useIsMobile();

  return (
    <Box as="footer" bg="#C5E6EB" color="0d0d5d" style={{ boxShadow: "7px 0px 18px 6px rgba(0, 0, 0, 0.8)" }} >
      <Flex
        direction={{ base: "column", md: "row" }}
        justify="center"
        align="center"
        mx="auto"
        maxW="7xl"
        alignItems="flex-start"
      >
        <Box flex="1" p={4}>
          <Text fontSize="lg" fontWeight="bold" fontStyle="italic" mb={4}>
            Oрдинација “ШУМЕНКОВСКИ”
          </Text>
          <Text>
            Стоматолошка ординација “ШУМЕНКОВСКИ” е основана 1998 година и е
            една од првите приватни ординации во Струга. Лоцирана е во срцето на
            градот и цели 25 години се трудиме секој пациент да добие
            најквалитетна стоматолошка услуга.
          </Text>
        </Box>

        {!isMobile && (
          <Box flex="1" p={4}>
            <Text fontSize="lg" fontWeight="bold" mb={4}>
              Контакт
            </Text>
            <Text>
              <LocationIcon />
              Маршал Тито бр. 64, Струга
            </Text>
            <Text pt={4}>
              <Icon as={EmailIcon} mr={3} />
              drshumenkovska@gmail.com
            </Text>
            <Text pt={4}>
              <Icon as={PhoneIcon} mr={3} />
              +389 46 780 201
            </Text>
          </Box>
        )}

        <Box flex="1" p={4}>
            <Text fontSize="lg" fontWeight="bold" mb={4}>
              Работно време
            </Text>
            <Text>Понеделник - Петок</Text>
            <Text>
              <Icon as={TimeIcon} mr={3} />
              09:00 - 17:00
            </Text>

            <Text fontSize="lg" fontWeight="bold" mb={4} mt={8}>
              Следете не
            </Text>
            <Link
              href="https://www.facebook.com/profile.php?id=100036964609669"
              mr={2}
            >
              <FacebookIcon />
            </Link>
            <Link
              href="https://www.instagram.com/dr_shumenkovska_orthodontics/?igshid=YzAwZjE1ZTI0Zg%3D%3D"
              mr={2}
            >
              <InstagramIcon />
            </Link>
        </Box>

        <Box flex="1" p={4}>
          <Text fontSize="lg" fontWeight="bold" mb={4}>
            Локација
          </Text>
          <AspectRatio width={isMobile ? "10rem" : "22rem"}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12012.112676326355!2d20.6748078!3d41.1775528!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350e719b823edd5%3A0xb18f2e4e0a765cbc!2sDental%20Clinic%20%22Shumenkovski%22!5e0!3m2!1sen!2smk!4v1699308529090!5m2!1sen!2smk"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            />
          </AspectRatio>
        </Box>
      </Flex>

      <Box textAlign="center" bg="#8DD1DA" p={4}>
        © {new Date().getFullYear()} Copyright - Стоматолошка ординација
        “ШУМЕНКОВСКИ”
      </Box>
    </Box>
  );
};

export default Footer;
