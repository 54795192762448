import React,{ useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  Text,
  Box,
  Icon,
  StackDivider,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { EmailIcon, PhoneIcon, TimeIcon } from "@chakra-ui/icons";
import { LocationIcon } from "../icons/icons";
import AppointmentModal from "./AppointmentModal";
import { useIsMobile } from "../default/ResponsiveUtils";

function Contact() {
  const isMobile = useIsMobile();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [currentPath]);
  return (
    <>
      <Box minW="38%" p={5} pt={10}>
        <Card
          bg="#e5efef"
          borderRadius={2}
          boxShadow="0px 0px 5px 2px rgba(0, 0, 0, 0.3)"
        >
          <CardHeader>
            <Heading align="center" size="md">
              Контакт информации и закажување термин
            </Heading>
          </CardHeader>

          <CardBody>
            <Stack divider={<StackDivider />} spacing="4">
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  Локација
                </Heading>
                <Text pt="2" fontSize="sm">
                  <LocationIcon />
                  Маршал Тито бр.64, Струга
                </Text>
              </Box>
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  Е-маил
                </Heading>
                <Text pt="2" fontSize="sm">
                  <Icon as={EmailIcon} mr={3} />
                  drshumenkovska@gmail.com
                </Text>
              </Box>
              <Box>
                <Heading size="xs" textTransform="uppercase" >
                  Телефон за контакт
                </Heading>
                <Text pt="2" fontSize="sm">
                  <Icon as={PhoneIcon} mr={3} />
                  +389 46 780 201
                </Text>
              </Box>
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  Работно време
                </Heading>
                <Text pt="2" fontSize="sm">
                  Понеделник - Петок
                </Text>
                <Text pt="2" fontSize="sm">
                  <Icon as={TimeIcon} mr={3} />
                  09:00 - 17:00
                </Text>
              </Box>
            </Stack>
          </CardBody>
        </Card>
      </Box>
      <Box pb={5}>
        <Button
          onClick={onOpen}
          width={isMobile ? "15rem" : "25rem"}
          height="3rem"
          fontSize="20px"
          bg="#C5E6EB"
          textColor="#0d0d5d"
          fontWeight="bold"
          _hover={{ boxShadow: "0px 0px 1px 2px rgba(0, 0, 0, 0.3)" }}
          borderRadius={2}
        >
          Закажи термин
        </Button>
      </Box>

      {isOpen && <AppointmentModal isOpen={isOpen} onClose={onClose} />}
    </>
  );
}

export default Contact;
