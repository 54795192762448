import React from "react";
import { InputGroup, Input, InputRightElement } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";

const CustomDateInput = ({ value, onClick }) => (
    <InputGroup>
      <Input
        borderRadius={2}
        value={value}
        onClick={onClick}
      />
      <InputRightElement>
        <CalendarIcon color="gray.500" mt={0} mr={2} />
      </InputRightElement>
    </InputGroup>
  );

  export default CustomDateInput;