import React, { useState } from "react";
import { Text, Link } from "@chakra-ui/react";

import { useIsMobile } from "../default/ResponsiveUtils";

const TruncatedText = ({ text, maxCharacters }) => {
  const isMobile = useIsMobile();
  const [showFullText, setShowFullText] = useState(false);

  const truncatedText = showFullText
    ? text
    : text.length > maxCharacters
    ? `${text.slice(0, maxCharacters)}...`
    : text;

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <Text
      border="2px solid #44838d"
      bg="#c2eaf0"
      color="#104d57"
      fontSize={isMobile ? 18 : 22}
      fontFamily="serif"
      overflowWrap="break-word"
      pt={3}
      pb={3}
      pl={5}
      pr={5}
    >
      {truncatedText}
      {text.length > maxCharacters && (
        <Link onClick={toggleFullText} ml={2} color="#0792a8">
          {showFullText ? "Помалку" : "Повеќе"}
        </Link>
      )}
    </Text>
  );
};

export default TruncatedText;
