import ServiceListEnum from "./ServiceListEnum";
import prevencija1 from "../images/prevencija1.jpg";
import prevencija2 from "../images/prevencija2.png";
import konzervativna1 from "../images/konzervativna1.jpg";
import konzervativna2 from "../images/konzervativna2.jpg";
import konzervativnaPlombi from "../images/konzervativnaPlombi.jpg";
import endodencija1 from "../images/endodencija1.jpg";
import detska1 from "../images/detska1.jpg";
import detska2 from "../images/detska2.jpg";
import protetikaIBelenje from "../images/protetikaIBelenje.jpg";
import protetikaTotalnaProteza from "../images/protetikaTotalnaProteza.jpg";
import protetikaMost from "../images/protetikaMost.png";
import protetikaParcijalna from "../images/protetikaParcijalna.png";
import homeBack1 from "../images/homeBack1.jpg";
import ortodoncijaFiksni1 from "../images/ortodoncijaFiksni1.jpg";
import ortodoncijaMobilni1 from "../images/ortodoncijaMobilni1.jpg";
import ortodoncijaMYobrace from "../images/ortodoncijaMYobrace.png";
import ortodoncijaInvisalign from "../images/ortodoncijaInvisalign.jpg";
import paradontologija from "../images/paradontologija.jpg";
import profesionalnoBeleenje from "../images/profesionalnoBeleenje.jpg";
import domasnoBeleenje from "../images/domasnoBeleenje.jpg";
import rendgen from "../images/rendgen.jpg";
import rendgenSnimka from "../images/rendgenSnimka.png";

const ServiceList = {
  [ServiceListEnum.PreventivnaStomatologija]: {
    title: "Превентивна стоматологија",
    image1: endodencija1,
    description:
      "Добра превентива и висока свест за орално здравје е бенефит за цел живот.",
    text1:
      "Едукациите за орална хигиена започнуваат уште од мала возраст кога најбитно е да се стекне редовна навика за одржување хигиена и редовни контроли на стоматолог. Флуорирањето на забите, чистењето меки наслаги и залевањето на фисурите на трајните заби се превентивни методи кои овозможуваат здрава насмевка.",
    image2: prevencija2,
  },
  [ServiceListEnum.KonzervativnaStomatologija]: {
    title: "Конзервативна стоматологија",
    image1: endodencija1,
    description:
      "Конзервативна стоматологија е лечење и пломбирање на забите и одржување на здрава насмевка.",
    text1:
      "Едно од најчестите заболувања на современиот човек е кариесот на забите. Тој настанува од константно делување на бактерии и нивни ензими на површината на забите. Неадекватната хигиена предизвикува деминерализација на забите, што води до разградување на емајлот и дентинот. Подолготрајно делување доведува и до неповратно оштетување на забниот нерв.",
    image2: konzervativna2,
    text2:
      "Кариесот во повеќе случаи се гледа како промена на бојата на забот или како дефект во самата структура на забот. Пломбирањето на забите во доба на модерна стоматологија е потполно безболно. Пломбите се со висок квалитет, цврсти и отпорни на пребојување.",
    image3: konzervativnaPlombi,
  },
  [ServiceListEnum.Endodencija]: {
    title: "Ендодонција",
    image1: endodencija1,
    description: "Ендодонција е лекување на каналите во коренот на забот.",
    text1:
      "Ендоденција е рутинска интервеција која се врши под дејство на локална анестезија и е потполно безболна метода. Многу е значајно да се разбере дека тоа е последниот чекор за зачувување на забот и единствено што преостанува е вадење на забот. Секогаш кога е возможно најдобро е забот да се зачува, да се излечат каналите и адекватно да се наполнат и да се надогради коронката, со цел неговата функција и естетика да се вратат во предходната ситуација.",
    image2: konzervativna1,
    text2:
      "Правилно излечен заб има подеднаков биолошки потенцијал како и здрав заб. Лекувањето на забите не би требало да го одложувате за следен пат, бидејќи кариозниот процес може само да напредува и да доведе до многубројни компликации кои секако може да ги избегнете доколку навремено се делува. Се применуваат најсовремени ендодонтски инструменти и машинска обработка на каналите во коренскиот дел од забот.",
    image3: prevencija1,
  },
  [ServiceListEnum.DetskaStomatologija]: {
    title: "Детска стоматологија",
    image1: endodencija1,
    description:
      "Во нашата ординација посебно внимание се посветува на нашите најмлади пациенти.",
    text1:
      "Секогаш се трудиме да воспоставиме другарски однос, да се намали стравот, а интервенцијата да се направи побезболно. Најбитно е првиот преглед на стоматолог да биде тогаш кога не боли ништо. Се препорачува првата посета на стоматолог да биде поврзана со првиот роденден.",
    image2: detska1,
    text2:
      "Стоматологот е тука да ја објасни секоја фаза од интервенцијата и да му овозможи на младиот пациент да го види и допре секој инструмент, а воедно да стекне доверба. Кога еднаш ќе се стекне доверба, секоја следна посета на стоматолог нема да претставува проблем.",
    image3: detska2,
  },
  [ServiceListEnum.Protetika]: {
    title: "Протетика",
    image1: endodencija1,
    description:
      "Протетика e естетска гранка на стоматологијата која се занимава со рехабилитација на загризот и надоместување на изгубените заби.",
    text1:
      "Постои широка палета протетски надоместоци, но би ги поделиле во две групи: мобилни (тотални, парцијални протези, визил, валпласт протези) и фиксни протетски надоместоци (металкерамички коронки и мостоци, безметални коронки и мостоци, инлеи, онлеи, надоградби, фасети). Фиксните надоместоци се многу покомфорни и подоби, но некогаш условите односно состојбата во устата ја диктира потребата за изработка на различни мобилни протези.",
    image2: protetikaTotalnaProteza,
    text2:
      "Во нашата ординација после преглед на пациентот се прави план на терапија во склад со желбата и можностите на пациентот и условите во устата. За пациенти кои имаат желби за фиксен надоместок, а моменталните услови не го дозволуваат тоа, секогаш постои можност за импланти.",
    image3: protetikaIBelenje,
    image4: protetikaTotalnaProteza,
    image5: protetikaMost,
    image6: protetikaParcijalna,
  },
  [ServiceListEnum.Ortodoncija]: {
    title: "Ортодонција",
    image1: endodencija1,
    description:
      "Ортодонција е специјалистичка стоматолошка гранка која се занимава со корекција на неправилности во растот и развојот на забите.",
    text1:
      "Различните ортодонтски неправилности успешно се решаваат во зависност од животната доба на пациентот, со фиксни или мобилни ортодонтски апарати. Клиничката слика и морфо-анатомските варијации ја одредуваат должината на траење на терапијата. Современата ортодонција овозможува многу опции за пациентите кои одлучиле да ги исправат своите заби и неправилниот загриз.",
    image2: homeBack1,
    text2:
      "Една од тие можности е мобилен ортодонтски апарат, кој се изработува индивидуално, поединечно за горна или долна вилица и неговата намена е за исправување на забите, проширување на забниот лак и корекција на неправилна положба на вилиците. Идеално време е возраст на мешовита дентиција, но одлични резултати се постигнуваат и кај повозрасни пациенти а се зависи од упорноста на носење на протезата.",
    image3: ortodoncijaMobilni1,
    text3:
      "Фиксната ортодонција не е само тренд, туку и потреба. Постојат повеќе видови фиксни апарати: стандардни фиксни протези (метални/естетски/златни) и самолигирачки (метални/естетски). Сите видови на фиксни протези имаат една иста цел. Тие помагаат да се исправат забите, лошиот загриз, ја корегираат мастикацијата, говорот и го подобруваат целокупниот изглед. Постигнувањето совршена насмевка не е лесно, бара многу време, трпение и добар ортодонт.",
    image4: ortodoncijaFiksni1,
    text4:
      "Myobrace System е терапија на исправување на забите и вилиците на природен начин, заснована на специјалната техника на миофункционалните апарати. Овие апарати имаат за цел да ги отстранат лошите навики. Myobrace апаратот со благи и континуирани сили влијае на померување и распоредување на забите. Myobrace апаратот се носи во текот на спиењето и по 1-2 часа преку ден. Во текот на денот овој апарат се комбинира со миофункционални вежби кои го подобруваат дишењето, положбата на јазикот и функцијата на мускулите. Myobrace терапијата има за цел исправување на забите и отстранување на причинителот кој довел до појава на проблем. Најдобар ефект има кај пациенти на возраст од 6 до 10 години, но и кај возрасни пациенти кај кои е потребна поголема упорност да се отстранат лошите навики. Myobrace терапијата ги дава следните резултати: правилно дишење на нос, а не на уста, правилна позиција на јазикот, правилен развој на лицето и исправување и корекција на забите без протеза, ритејнер или вадење на заби.",
    image5: ortodoncijaMYobrace,
    text5:
      "Invisalign фолии се најмодерна технологија за исправување на забите. Овие технолошки, ортодонтски помагала се скоро потполно невидливи, па затоа се и најбараниот производ меѓу повозрасните пациенти. Invisalign третманот е наменет за сите пациенти кои сакаат да ги исправат своите заби или лош загриз, ставајќи акцент на зачувување на природната насмевка. Најголеми предности се: дискретност (невидливи се), флексибилност (може да се става и вади од уста кога ќе се посака), брзи ефекти (првите резултати се видливи после 2 недели). Препорачано време на носење е 22 часа дневно.",
    image6: ortodoncijaInvisalign,
  },
  [ServiceListEnum.RendgenDijagnostika]: {
    title: "Рендген дијагностика",
    image1: endodencija1,
    description:
      "Нашиот рендген кабинет овозможува ортопантомографски и интраалвеоларни снимања.",
    text1:
      "Ортопантомографско или панорамско снимање овозможува преглед на целото забало, евалуација на импактирани заби, проценка на состојбата на виличните коски, детекција на заболувања, трауми, фрактури, проценка на состојба на пародонтот, проценка на висина на алвеоларна коска, планирање имплантолошки третмани, планирање ортодонтски третмани, детекција на пореметувања во ТМЗ, воспаленија на максиларни синуси и др.",
    image2: rendgen,
    text2:
      "Интраалвеоларни снимки овозможуваат детекција на апикални инфекции, проценка на пародонтални заболувања, проценка на ендодонтски третмани во сите фази на лекување, детална евалуација на апикални цисти и др лезии на алвеоларна коска, постоперативна евалуација на поставени импланти, проценка на морфологија на заб, проценка на импактирани заби, пред и постоперативен увид на апикотомија на заби и др.",
    image3: rendgenSnimka,
  },
  [ServiceListEnum.ParadontoloskiTretmani]: {
    title: "Пародонтолошки третмани",
    image1: endodencija1,
    description:
      "Пародонтологија е дел од стоматологијата која се занимава со спречување, рана дијагноза и лечење на пародонтопатија.",
    text1:
      "Пародонтопатија претставува сериозно заболување на гингивата, потпорниот апарат на забот и виличната коска. После кариесот тоа е најзастапена болест на забите, а испитувањата укажуваат дури и дека кај 90% од популацијата се јавува некаков облик на пародонтопатија, без разлика на возраста. Редовните посети на стоматолог и соодветната орална хигиена претставуваат превенција, но и рано дијагностицирање на оваа болест.",
    text2:
      "Ако се открие на време, соодветна терапија спречува прогресивно напредување. На самиот почеток на болеста симптомите се многу благи, незначителни, без тегоби иако болеста е присутна. Редовните контроли на стоматолог можат да спречат напредување на болеста, чиј краен исход е губиток на заби. Во нашата ординација се применува современ конзервативен пародонтолошки третман (Full Mouth Treatment – 24часовен третман) кој контролира и успорува понатамошно прогредурање на болеста.",
    image2: paradontologija,
    image3: prevencija1,
    text3:
      "Причинители за појава на пародонтопатија се патогени микроорганизми и нивните продукти во забните наслаги и камен. Покрај овие фактори, на развојот на болеста влијаат и лошата орална хигиена, пушењето, исхрана сиромашна со витамини и минерали, имунолошки заболувања на организмот, генетски фактори, дијабетес и неадекватна протеза. Болеста започнува со воспалување на непцата, оток, крварења (гингивитис), што ако не се третира се проширува со настанување на пародонтални џебови, повлекување на гингивата, изложеност на коренот, нишање и испаѓање на забите. Специфично е што пациентот не чувствува болка се додека недојде до компликации.",
    image4: prevencija2,
  },
  [ServiceListEnum.BelenjeNaZabi]: {
    title: "Белење заби",
    image1: endodencija1,
    description:
      "Белење на забите е естетска стоматолошка процедура со која на забите се враќа сјајот и белината.",
    text1:
      "Процесот не белеење е едноставен и безболен. Започнува со детално чистење на меките и цврсти наслаги од забите и одредување на почетна нијанса на заби. Следната фаза е да се стави заштита на гингивата, што недозволува средството за белење да дојде во контакт со меките ткива. Потоа се аплицира средството за белење на површината на забите и се активира гелот со лампата за белење. Цела процедура е три дена. Осетливоста на забите која се јавува после белење на забите е привремена и перзистира до 24 часа.",
    image2: profesionalnoBeleenje,
    text2:
      "Покрај ординациско постои и домашно белење. Тоа е подолготрајна процедура (5-7 дена). Се изработуваат индивидуални фолии во кои пациентот сам аплицира гел и ги носи во домашни услови. Таа процедура е покомфорна, бидејќи пациентот сам го одредува периодот кога ќе се белее и кога ќе ја прекине процедурата. Максимална препорачана терапија е по еден саат во тек на 7 дена.",
    image3: domasnoBeleenje,
  },
};

export default ServiceList;