import React,{ useState, useEffect } from "react";
import { GridItem, Image, SimpleGrid, Text, Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useIsMobile } from "../default/ResponsiveUtils";
import bA1 from "../images/bA1.jpg";
import bA2 from "../images/bA2.jpg";
import bA3 from "../images/Publication7.jpg"
import bA4 from "../images/Publication9.jpg"
import bA5 from "../images/Publication18.jpg"
import bA6 from "../images/Publication115.jpg"
import bA7 from "../images/Publication119.jpg"



function BeforeAndAfter() {
  const isMobile = useIsMobile();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [currentPath]);

  return (
    <SimpleGrid
      pb={isMobile ? 10 : 5}
      pt={5}
      columns={1}
      margin="auto"
      spacing={12}
    >
      <SimpleGrid columns={1} spacing={5}>
        <GridItem align="center">
          <Box bg="#c2eaf0" borderRadius={2} border="2px solid #44838d">
            <Text
              fontSize={isMobile ? 22 : 30}
              fontWeight="bold"
              fontFamily="serif"
              textColor="#104d57"
            >
              Пред и потоа
            </Text>
          </Box>
        </GridItem>
        {/* <GridItem align="center">
          <Image
            border="6px solid white"
            src={bA1}
            width={isMobile ? "22rem" : "28rem"}
            height="24rem"
            style={{ boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.3)" }}
          />
        </GridItem>
        <GridItem align="center">
          <Image
            border="6px solid white"
            src={bA2}
            width={isMobile ? "22rem" : "28rem"}
            height="24rem"
            style={{ boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.3)" }}
          />
        </GridItem> */}
      </SimpleGrid>
      <SimpleGrid columns={isMobile ? 1 : 2} spacing={5}>
        <GridItem align="center">
          <Image
            border="6px solid white"
            src={bA1}
            width={isMobile ? "22rem" : "28rem"}
            height="24rem"
            style={{ boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.3)" }}
          />
        </GridItem>
        <GridItem align="center">
          <Image
            border="6px solid white"
            src={bA2}
            width={isMobile ? "22rem" : "28rem"}
            height="24rem"
            style={{ boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.3)" }}
          />
        </GridItem>

        <GridItem align="center">
          <Image
            border="6px solid white"
            src={bA3}
            width={isMobile ? "22rem" : "28rem"}
            height="24rem"
            style={{ boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.3)" }}
          />
        </GridItem>

        <GridItem align="center">
          <Image
            border="6px solid white"
            src={bA4}
            width={isMobile ? "22rem" : "28rem"}
            height="24rem"
            style={{ boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.3)" }}
          />
        </GridItem>

        <GridItem align="center">
          <Image
            border="6px solid white"
            src={bA5}
            width={isMobile ? "22rem" : "28rem"}
            height="24rem"
            style={{ boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.3)" }}
          />
        </GridItem>

        <GridItem align="center">
          <Image
            border="6px solid white"
            src={bA6}
            width={isMobile ? "22rem" : "28rem"}
            height="24rem"
            style={{ boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.3)" }}
          />
        </GridItem>

        <GridItem align="center">
          <Image
            border="6px solid white"
            src={bA7}
            width={isMobile ? "22rem" : "28rem"}
            height="24rem"
            style={{ boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.3)" }}
          />
        </GridItem>
      </SimpleGrid>
    </SimpleGrid>
  );
}

export default BeforeAndAfter;
