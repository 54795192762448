import React from "react";
import { Box } from "@chakra-ui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import ImageSlide from "./ImageSlide";
import { useIsMobile } from "../default/ResponsiveUtils";

const CarouselComponent = ({ slides }) => {
  const isMobile = useIsMobile();

  return (
    <Box>
        <Carousel 
        width={isMobile ? "23rem" : "60rem"}
        autoPlay={true}
        infiniteLoop={true}
        showStatus={false}
        transitionTime={1000} 
        stopOnHover={true} 
        dynamicHeight={true}
      >
        {slides.map((slide, index) => (
          <Box key={index}>
            <ImageSlide {...slide} />
          </Box>
        ))}
      </Carousel>
    </Box>
    
  );
};

export default CarouselComponent;
