import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Text,
  Flex, 
  Spacer
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import ServiceList from "../default/ServiceList";
import ServiceListEnum from "../default/ServiceListEnum";
import TruncatedText from "./TruncatedText";
import { useIsMobile } from "../default/ResponsiveUtils";

const Services = () => {
  const { id } = useParams();
  const location = useLocation();
  const isMobile = useIsMobile();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const serviceId = parseInt(id, 10);
  const serviceDetails = ServiceList[serviceId];

  const isPreventivnaStomatologija =
    serviceId === ServiceListEnum.PreventivnaStomatologija;
  const isProtetika = serviceId === ServiceListEnum.Protetika;
  const isOrtodoncija = serviceId === ServiceListEnum.Ortodoncija;
  const isParadontoloskiTretmani =
    serviceId === ServiceListEnum.ParadontoloskiTretmani;

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [currentPath]);

  return (
    <>
      <Box position="relative" width="fit-content" alignItems="center">
        <Box width="fit-content" mx="auto">
          <Image  
            src={serviceDetails.image1}
            width={isMobile ? "24rem" : "full"}
            height={isMobile ? "20rem" : "48rem"}
          />
          <Flex width="full" position="absolute"
              top="53%">
                <Box></Box>
                <Spacer></Spacer>
            <Box mx="auto"
              bg="#44838d"
              textColor="#e1eef0"
              width={isMobile ? "18.1rem" : "40.5rem"}
              p={2}>
              <Heading
                fontSize={isMobile ? 17 : 46}
                fontFamily="serif"
                overflowWrap="break-word"
                whiteSpace="normal"
              >
                {serviceDetails.title}
              </Heading>
              <Box pt={2}>
                <Text
                  fontSize={isMobile ? 16 : 24}
                  fontFamily="serif"
                  overflowWrap="break-word"
                  whiteSpace="normal"
                >
                  {serviceDetails.description}
                </Text>
              </Box>
            </Box>
          </Flex>
        </Box>
       
      </Box>
      <Box pb={5}>
        <SimpleGrid
          pb={5}
          pt={10}
          columns={isMobile ? 1 : 2}
          margin="auto"
          width="80%"
        >
          <GridItem pl={isMobile ? 0 : 10} pb={isMobile ? 5 : 0}>
            <TruncatedText text={serviceDetails.text1} maxCharacters={480} />
          </GridItem>
          <GridItem pl={isMobile ? 0 : 10}>
            <Image
              src={serviceDetails.image2}
              width="34.5rem"
              height="20.5rem"
              style={{ boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.3)" }}
            />
          </GridItem>
        </SimpleGrid>
        {!isPreventivnaStomatologija && (
          <SimpleGrid
            pb={5}
            pt={5}
            columns={isMobile ? 1 : 2}
            margin="auto"
            width="80%"
          >
            <GridItem pl={isMobile ? 0 : 10}>
              <Image
                src={serviceDetails.image3}
                width="36rem"
                height="20.5rem"
                style={{ boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.3)" }}
              />
            </GridItem>
            <GridItem pl={isMobile ? 0 : 10} pt={isMobile ? 5 : 0}>
              <TruncatedText text={serviceDetails.text2} maxCharacters={480} />
            </GridItem>
          </SimpleGrid>
        )}
        {isProtetika && (
          <SimpleGrid
            pb={5}
            pt={5}
            columns={isMobile ? 1 : 3}
            spacing={10}
            margin="auto"
            width="80%"
          >
            <GridItem align="center" pl={isMobile ? 0 : 3}>
              <Image
                src={serviceDetails.image4}
                width="18rem"
                height="15rem"
                style={{ boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.3)" }}
              />
            </GridItem>
            <GridItem align="center" pl={isMobile ? 0 : 10}>
              <Image
                src={serviceDetails.image5}
                width="18rem"
                height="15rem"
                style={{ boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.3)" }}
              />
            </GridItem>
            <GridItem align="center" pl={isMobile ? 1 : 10}>
              <Image
                src={serviceDetails.image6}
                width="18rem"
                height="15rem"
                style={{ boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.3)" }}
              />
            </GridItem>
          </SimpleGrid>
        )}
        {isOrtodoncija && (
          <>
            <SimpleGrid
              pb={5}
              pt={5}
              columns={isMobile ? 1 : 2}
              margin="auto"
              width="80%"
            >
              <GridItem pl={isMobile ? 0 : 10} pb={isMobile ? 5 : 0}>
                <TruncatedText
                  text={serviceDetails.text3}
                  maxCharacters={480}
                />
              </GridItem>
              <GridItem pl={isMobile ? 0 : 10}>
                <Image
                  src={serviceDetails.image4}
                  width="34.5rem"
                  height="20.5rem"
                  style={{ boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.3)" }}
                />
              </GridItem>
            </SimpleGrid>
            <SimpleGrid
              pb={5}
              pt={5}
              columns={isMobile ? 1 : 2}
              margin="auto"
              width="80%"
            >
              <GridItem pl={isMobile ? 0 : 10} pb={isMobile ? 5 : 0}>
                <Image
                  src={serviceDetails.image5}
                  width="36rem"
                  height="20.5rem"
                  style={{ boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.3)" }}
                />
              </GridItem>
              <GridItem pl={isMobile ? 0 : 10}>
                <TruncatedText
                  text={serviceDetails.text4}
                  maxCharacters={480}
                />
              </GridItem>
            </SimpleGrid>
            <SimpleGrid
              pb={5}
              pt={5}
              columns={isMobile ? 1 : 2}
              margin="auto"
              width="80%"
            >
              <GridItem pl={isMobile ? 0 : 10} pb={isMobile ? 5 : 0}>
                <TruncatedText
                  text={serviceDetails.text5}
                  maxCharacters={480}
                />
              </GridItem>
              <GridItem pl={isMobile ? 0 : 10}>
                <Image
                  src={serviceDetails.image6}
                  width="34.5rem"
                  height="20.5rem"
                  style={{ boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.3)" }}
                />
              </GridItem>
            </SimpleGrid>
          </>
        )}
        {isParadontoloskiTretmani && (
          <SimpleGrid pb={5} pt={5} columns={isMobile ? 1 : 2} margin="auto" width="80%">
            <GridItem pl={isMobile ? 0 : 10} pb={isMobile ? 5 : 0}>
              <TruncatedText text={serviceDetails.text3} maxCharacters={480} />
            </GridItem>
            <GridItem pl={isMobile ? 0 : 10}>
              <Image
                src={serviceDetails.image4}
                width="34.5rem"
                height="20.5rem"
                style={{ boxShadow: "1px 2px 4px 2px rgba(0, 0, 0, 0.3)" }}
              />
            </GridItem>
          </SimpleGrid>
        )}
      </Box>
    </>
  );
};

export default Services;
