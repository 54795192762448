import React from "react";
import {
  useDisclosure,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  VStack,
  Collapse,
  Button,
  Image,
  HStack,
} from "@chakra-ui/react";
import { ChevronDownIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

import shumenkovski_black from "../images/shumenkovski_black.png";
import logo from "../images/logo.png";

const HamburgerMenu = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isServicesOpen, onToggle: onServicesToggle } =
    useDisclosure();

  return (
    <HStack bg="#e5efef" style={{ boxShadow: "1px 0px 10px 4px rgba(0, 0, 0, 0.5)" }}>
      <IconButton
        height="4rem"
        width="4rem"
        size={"lg"}
        icon={<HamburgerIcon />}
        display={{ md: "none" }}
        onClick={isOpen ? onClose : onOpen}
        borderRadius={1}
      />
      {!isOpen && (
        <>
          <Image src={shumenkovski_black} maxH="4rem" width="20rem" />
        </>
      )}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent
          height="full"
          bg="#e5efef"
          _before={{
            content: '""',
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundImage: `url(${logo})`,
            backgroundSize: "320px 525px",
            bgRepeat: "no-repeat",
            bgPos: "center",
            opacity: 0.5,
          }}
        >
          <DrawerCloseButton />
          <DrawerHeader></DrawerHeader>
          <DrawerBody>
            <VStack>
              <Button
                variant="ghost"
                onClick={onClose}
                as={Link}
                to="/"
                fontSize={18}
                fontWeight="bold"
                textColor="#0d0d5d"
              >
                Почетна
              </Button>
              <Button
                variant="ghost"
                onClick={onClose}
                as={Link}
                to="/about"
                fontSize={18}
                fontWeight="bold"
                textColor="#0d0d5d"
              >
                За нас
              </Button>
              <Button
                variant="ghost"
                as={Link}
                onClick={onServicesToggle}
                borderRadius={2}
                fontSize={18}
                fontWeight="bold"
                textColor="#0d0d5d"
                rightIcon={<ChevronDownIcon />}
              >
                Услуги
              </Button>
              <Collapse in={isServicesOpen} align="stretch">
                <VStack pl={4} spacing={0}>
                  <Button
                    variant="ghost"
                    onClick={onClose}
                    as={Link}
                    to="/services/1"
                    textColor="#0d0d5d"
                  >
                    Превентивна стоматологија
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={onClose}
                    as={Link}
                    to="/services/2"
                    textColor="#0d0d5d"
                  >
                    Конзервативна стоматологија
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={onClose}
                    as={Link}
                    to="/services/3"
                    textColor="#0d0d5d"
                  >
                    Ендодонција
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={onClose}
                    as={Link}
                    to="/services/4"
                    textColor="#0d0d5d"
                  >
                    Детска стоматологија
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={onClose}
                    as={Link}
                    to="/services/5"
                    textColor="#0d0d5d"
                  >
                    Протетика
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={onClose}
                    as={Link}
                    to="/services/6"
                    textColor="#0d0d5d"
                  >
                    Ортодонција
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={onClose}
                    as={Link}
                    to="/services/7"
                    textColor="#0d0d5d"
                  >
                    Рендген дијагностика
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={onClose}
                    as={Link}
                    to="/services/8"
                    textColor="#0d0d5d"
                  >
                    Пародонтолошки третмани
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={onClose}
                    as={Link}
                    to="/services/9"
                    textColor="#0d0d5d"
                  >
                    Белење заби
                  </Button>
                </VStack>
              </Collapse>
              <Button
                variant="ghost"
                onClick={onClose}
                as={Link}
                to="/beforeAfter"
                fontSize={18}
                fontWeight="bold"
                textColor="#0d0d5d"
              >
                Пред и Потоа
              </Button>
              <Button
                variant="ghost"
                onClick={onClose}
                as={Link}
                to="/contact"
                fontSize={18}
                fontWeight="bold"
                textColor="#0d0d5d"
              >
                Контакт
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </HStack>
  );
};

export default HamburgerMenu;
